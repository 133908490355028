import useTailwindConfig from '@/composables/helpers/useTailwindConfig';
import { AvatarColor } from '@/types/profile';

const { theme } = useTailwindConfig();

const backgroundColorsMap: Record<AvatarColor, string> = {
  [AvatarColor.Orange]: theme?.colors['brand-light-orange'],
  [AvatarColor.Green]: theme?.colors['brand-light-green'],
  [AvatarColor.Red]: theme?.colors['brand-light-red'],
  [AvatarColor.Purple]: theme?.colors['brand-light-purple'],
  [AvatarColor.Blue]: theme?.colors['brand-light-blue']
};

export default function useAvatarThemeColor(color: AvatarColor) {
  return backgroundColorsMap[color];
}
