<script lang="ts" setup>
import { AvatarColor, AvatarImage } from '@/types/profile';
import useAvatarThemeColor from '@/composables/helpers/useAvatarThemeColor';

interface Props {
  image?: AvatarImage;
  color?: AvatarColor;
  className?: string;
}

const props = withDefaults(defineProps<Props>(), {
  image: AvatarImage.Crown,
  color: AvatarColor.Orange
});

const avatarStyle = computed(() => {
  return {
    backgroundColor: useAvatarThemeColor(props.color)
  };
});

const avatarImageSrc = computed(() => {
  return `/images/avatars/${props.image}.png`;
});
</script>

<template>
  <div
    :class="[
      'flex items-center justify-center rounded-full min-w-10 min-h-10',
      className
    ]"
    :style="avatarStyle"
  >
    <NuxtImg :src="avatarImageSrc" class="w-4/6 h-4/6" />
  </div>
</template>
