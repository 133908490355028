import { TRUMPS_WINS_STORE_KEY } from '@/stores/statistic/constants';
import {
  type StatisticPageMeta,
  Timeframes,
  type TrumpsWinsData
} from '@/types/statistic';
import { useTrumpsWins } from '@/composables/api/actions/statistic';
import { useWatchParam } from '@/stores/statistic/helpers';

export const useTrumpsWinsStore = defineStore(TRUMPS_WINS_STORE_KEY, () => {
  const timeframe = ref<Timeframes>(Timeframes.Month);
  const unitOffset = ref(0);
  const data = ref<TrumpsWinsData>({
    trumps: [],
    nt: []
  });
  const meta = ref<StatisticPageMeta>({
    hasPreviousPage: false,
    hasNextPage: false,
    dateLabel: ''
  });

  const dataIsEmpty = computed<boolean>(() => {
    return !data.value?.trumps?.length || !data.value?.nt?.length;
  });

  async function getData() {
    const response = await useTrumpsWins({
      timeframe: timeframe.value,
      unitOffset: unitOffset.value
    });

    if (response.data) {
      data.value = response.data.data;
      meta.value = response.data.meta;
    }

    return response;
  }

  function resetStore() {
    data.value = {
      trumps: [],
      nt: []
    };
    timeframe.value = Timeframes.Month;
    unitOffset.value = 0;
    meta.value = {
      hasPreviousPage: false,
      hasNextPage: false,
      dateLabel: ''
    };
  }

  useWatchParam(timeframe, getData);
  useWatchParam(unitOffset, getData);

  return {
    timeframe,
    unitOffset,
    data,
    dataIsEmpty,
    meta,
    getData,
    resetStore
  };
});
