import type { NitroFetchOptions } from 'nitropack';
import setupInterceptors from '@/composables/api/interceptors';
import type { ApiResponse } from '@/composables/api/types';

export const useApi = async <T>(
  baseURL: string,
  url: string,
  options?: NitroFetchOptions<string>,
  isFormData = false,
  transformCase = false
): Promise<ApiResponse<T>> => {
  try {
    const response = await $fetch<T>(url, {
      baseURL,
      ...options,
      ...setupInterceptors({ isFormData, transformCase })
    });
    return { data: response, error: undefined };
  } catch (error: any) {
    return { data: undefined, error: createError(error) };
  }
};

export const useBaseApi = async <T>(
  url: string,
  options?: NitroFetchOptions<string>,
  isFormData = false,
  transformCase = false
): Promise<ApiResponse<T>> => {
  const config = useRuntimeConfig();

  return useApi<T>(
    config.public.baseApiUrl + '/api',
    url,
    options,
    isFormData,
    transformCase
  );
};

export const useBridgeApi = async <T>(
  url: string,
  options?: NitroFetchOptions<string>,
  isFormData = false,
  transformCase = true
): Promise<ApiResponse<T>> => {
  const config = useRuntimeConfig();

  return useApi<T>(
    config.public.bridge128ApiUrl,
    url,
    options,
    isFormData,
    transformCase
  );
};
