<script lang="ts" setup>
import { AvatarImage } from '@/types/profile';

interface Props {
  currentImage?: AvatarImage;
}

defineProps<Props>();

const emit = defineEmits(['selectImage']);

const avatarOptions = computed<AvatarImage[]>(() => {
  return Object.values(AvatarImage);
});

const avatarImageSrc = (image: AvatarImage) => {
  return `/images/avatars/${image}.png`;
};
</script>

<template>
  <div class="flex flex-col">
    <h3 class="text-brand-navy-600 text-lg leading-6 font-medium mb-3">
      2. Choose your avatar
    </h3>
    <ul
      class="flex flex-wrap md:grid md:grid-cols-5 md:grid-rows-2 gap-2 !list-none !ml-0"
    >
      <li
        v-for="image in avatarOptions"
        :key="image"
        :class="[
          'inline-flex justify-center items-center w-9 h-9 rounded-full cursor-pointer bg-brand-white border',
          image === currentImage ? 'border-brand-navy-600' : 'border-white'
        ]"
        @click="emit('selectImage', image)"
      >
        <NuxtImg :src="avatarImageSrc(image)" class="w-5 h-5" />
      </li>
    </ul>
  </div>
</template>
