export enum HandleStatus {
  Missed = 'missed',
  Won = 'won',
  Lost = 'lost',
  Ongoing = 'ongoing',
  Upcoming = 'upcoming'
}

export interface StreakDay {
  status: HandleStatus;
  dayName: string;
}

export interface Deal {
  // TODO: Add properties
  // ...
}

export interface Handle {
  id: number;
  date: string;
  difficultyLevel: number;
  deal: Deal;
  status: HandleStatus;
}

export interface Tournament {
  id: number;
  isPublished: boolean;
  week: number;
  handles: Handle[];
}

export interface CreateRoomPayload {
  metadata?: Record<string, any>;
  exitRedirectUrl?: string;
  exitButtonText?: string;
}

export interface CreateRoomResponse {
  roomToken: string;
}

export interface AnonymousScoreExistsResponse {
  exists: boolean;
}
