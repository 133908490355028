import { useBaseApi } from '@/composables/api';
import type {
  StatisticParams,
  StatisticPageMeta,
  LevelProgressPoint,
  TrumpsWinsData,
  WinsPerLevelPoint
} from '@/types/statistic';
import type { PaginatedData } from '@/types/pagination';

export const useWinsPerLevel = (params: StatisticParams) => {
  return useBaseApi<WinsPerLevelPoint[]>('/stats/wins-per-level', {
    method: 'get',
    params
  });
};

export const useLevelProgress = (params: StatisticParams) => {
  return useBaseApi<PaginatedData<LevelProgressPoint, StatisticPageMeta>>(
    '/stats/level-progress',
    {
      method: 'get',
      params
    }
  );
};

export const useTrumpsWins = (params: StatisticParams) => {
  return useBaseApi<{ data: TrumpsWinsData; meta: StatisticPageMeta }>(
    '/stats/trumps-nt-wins',
    {
      method: 'get',
      params
    }
  );
};
