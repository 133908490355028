import { useBaseApi, useBridgeApi } from '@/composables/api';
import type {
  SignUpCredentials,
  LoginCredentials,
  AuthResponse,
  BridgeAuthResponse,
  UpdateEmailResponse,
  UpdateEmailCredentials,
  UpdatePasswordCredentials,
  UpdatePasswordResponse,
  SignInWithGoogleCredentials
} from '@/types/auth';

export const useSignUp = (credentials: SignUpCredentials) => {
  return useBaseApi<AuthResponse>('/auth/sign-up', {
    method: 'post',
    body: credentials
  });
};
export const useLogIn = (credentials: LoginCredentials) => {
  return useBridgeApi<BridgeAuthResponse>('/auth/login', {
    method: 'post',
    body: credentials
  });
};

export const useSignInWithGoogle = (
  credentials: SignInWithGoogleCredentials
) => {
  return useBaseApi<AuthResponse>('/oauth/google/callback', {
    method: 'post',
    body: credentials
  });
};

export const useRefreshAuth = () => {
  return useBridgeApi<BridgeAuthResponse>('/auth/refresh', {
    method: 'get'
  });
};

export const useUpdateEmail = (credentials: UpdateEmailCredentials) => {
  return useBridgeApi<UpdateEmailResponse>('/auth/email', {
    method: 'put',
    body: credentials
  });
};

export const useUpdatePassword = (credentials: UpdatePasswordCredentials) => {
  return useBridgeApi<UpdatePasswordResponse>('/auth/setpassword', {
    method: 'put',
    body: credentials
  });
};
