import { useBaseApi } from '@/composables/api';
import type { Profile } from '@/types/profile';

export const useProfile = () => {
  return useBaseApi<Profile>('/profile/me', {
    method: 'get'
  });
};

export const useUpdateProfile = (data: Partial<Profile>) => {
  return useBaseApi<Profile>('/profile/me', {
    method: 'put',
    body: data
  });
};
