<script lang="ts" setup>
import { useModalStore } from '@/stores/modal';
import { useAuthStore } from '@/stores/auth';
import { AvatarColor, AvatarImage } from '@/types/profile';
import useToast from '@/composables/helpers/useToast';
import AvatarColorSelection from '@/components/Account/AvatarSelection/AvatarColorSelection.vue';
import AvatarImageSelection from '@/components/Account/AvatarSelection/AvatarImageSelection.vue';

const { profile } = storeToRefs(useAuthStore());
const { updateProfile } = useAuthStore();
const { closeModal, props } = useModalStore();
const toast = useToast();

const modalTitle = computed(() => {
  return props.signUp ? 'Let’s get started' : 'Edit your avatar';
});

const loading = ref(false);

const avatarImage = ref<AvatarImage>(
  profile.value?.avatarImage || profile.value?.avatarImage || AvatarImage.Crown
);

const avatarColor = ref<AvatarColor>(
  profile.value?.avatarColor || AvatarColor.Orange
);

function selectColor(color: AvatarColor) {
  avatarColor.value = color;
}

function selectImage(image: AvatarImage) {
  avatarImage.value = image;
}

async function handleSave() {
  if (!loading.value) {
    loading.value = true;

    const { error } = await updateProfile({
      avatarColor: avatarColor.value,
      avatarImage: avatarImage.value
    });

    if (error) {
      toast.error('Something went wrong');
    } else {
      toast.success('Avatar updated');
      closeModal();
    }

    loading.value = false;
  }
}
</script>

<template>
  <Modal :title="modalTitle" size="sm">
    <div class="grid md:grid-cols-2 gap-4 md:gap-9 p-2 md:p-0">
      <div class="flex flex-col">
        <AvatarColorSelection
          :current-color="avatarColor"
          @select-color="selectColor"
        />
        <hr
          class="inline-flex h-0.5 justify-center items-center my-4 overflow-visible border-brand-navy-100"
        />
        <AvatarImageSelection
          :current-image="avatarImage"
          @select-image="selectImage"
        />
      </div>
      <div
        class="flex flex-col items-center bg-white rounded-md pt-6 px-4 pb-3"
      >
        <Avatar
          :image="avatarImage"
          :color="avatarColor"
          class-name="!w-[110px] !h-[110px] mb-8"
        />
        <Button
          :loading="loading"
          color="secondary"
          size="sm"
          block
          @click="handleSave"
        >
          Confirm
        </Button>
      </div>
    </div>
  </Modal>
</template>
