import * as vt from 'vue-toastification';
import { POSITION } from 'vue-toastification';
import type { PluginOptions } from 'vue-toastification/src/types';

const options: PluginOptions = {
  timeout: 3000,
  position: POSITION.BOTTOM_RIGHT,
  maxToasts: 3,
  hideProgressBar: true,
  closeButton: false,
  toastClassName: 'brand-toast',
  transition: 'Vue-Toastification__custom-slide'
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(vt.default, options);
  return {
    provide: {
      toast: vt.useToast()
    }
  };
});
