<script lang="ts" setup>
import { cva } from 'class-variance-authority';
import { useModalStore } from '@/stores/modal';

const { closeModal } = useModalStore();

enum Sizes {
  Small = 'sm',
  Medium = 'md'
}

interface Props {
  title: string;
  size?: Sizes;
}

const props = withDefaults(defineProps<Props>(), {
  size: Sizes.Medium
});

const modalClasses = computed(() => {
  return cva('modal', {
    variants: {
      size: {
        [Sizes.Small]: 'w-[510px]',
        [Sizes.Medium]: 'w-[780px]'
      }
    }
  })({
    size: props.size
  });
});
</script>

<template>
  <div :class="modalClasses">
    <div
      class="flex items-center justify-between bg-white py-4 px-4 md:px-6 text-brand-navy-700 font-medium text-lg leading-6 rounded-t-[10px]"
    >
      <slot name="title">{{ title }}</slot>
      <button class="" @click="closeModal">
        <Icon name="ant-design:close-outlined" size="22" />
      </button>
    </div>
    <div class="modal-body">
      <slot v-bind="{ close: closeModal }" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  @apply flex flex-col max-w-full;

  max-height: calc(100vh - theme('spacing.10'));
}

:deep(.modal-body) {
  @apply grid gap-4 bg-brand-light-gray p-4 md:p-6 text-brand-navy-600 rounded-b-[10px] overflow-y-auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-medium;
  }

  h1 {
    @apply text-[22px] leading-7;
  }

  h2 {
    @apply text-lg leading-6;
  }

  h3 {
    @apply text-base leading-6;
  }

  p {
    @apply text-base leading-5;
  }

  ul,
  ol {
    @apply ml-6;

    li {
      @apply text-base leading-7;
    }
  }

  ul {
    @apply list-disc [&_ul]:list-[revert];
  }

  ol {
    @apply list-decimal;
  }

  a {
    @apply underline;
  }
}
</style>
