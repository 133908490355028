import * as Toast from 'vue-toastification';

export default function useToast() {
  const { $toast } = useNuxtApp();

  // TODO: Find a better way to solve this bug
  //  https://github.com/nuxt/module-builder/issues/215
  //  https://github.com/nuxt/nuxt/issues/24885
  return $toast as ReturnType<typeof Toast.useToast>;
}
