import type { FetchContext, FetchRequest } from 'ofetch';
import { useAuthStore } from '@/stores/auth';
import {
  addHeaders,
  formatRequestData,
  formatResponseData
} from '@/composables/api/helpers';
import { LOGOUT_ON_ERROR_STATUS } from '@/utils/constants';

export interface InterceptorsOptions {
  isFormData: boolean;
  transformCase: boolean;
}

export default function ({ isFormData, transformCase }: InterceptorsOptions) {
  const IGNORE_ON_REFRESH: FetchRequest[] = ['/auth/refresh'];
  const { refreshToken } = useAuthStore();
  const debouncedRefreshToken = debouncePromise(() => {
    return refreshToken();
  }, 100);

  const onRequest = async ({
    request,
    options
  }: FetchContext): Promise<void> => {
    console.log(`[fetch request] - ${request}`);

    const { token, refreshAfter, tokenRefreshInProgress } =
      storeToRefs(useAuthStore());

    if (request && !IGNORE_ON_REFRESH.includes(request)) {
      if (tokenRefreshInProgress.value) {
        await waitUntil(() => {
          return !tokenRefreshInProgress.value;
        });
      }

      if (refreshAfter.value && refreshAfter.value < Date.now()) {
        await debouncedRefreshToken();
      }
    }

    if (options.body && transformCase && !isFormData) {
      options.body = formatRequestData(options.body);
    }

    options.headers = addHeaders(
      {
        ...(token.value && { Authorization: `Bearer ${token.value}` }),
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json'
      },
      options.headers
    );
  };

  const onRequestError = async ({ request }: FetchContext): Promise<void> => {
    console.log(`[fetch request error] - ${request}`);
  };

  const onResponse = async ({
    request,
    response
  }: FetchContext): Promise<void> => {
    console.log(`[fetch response] - ${request}`);

    if (response?._data && transformCase) {
      response._data = formatResponseData(response._data);
    }
  };

  const onResponseError = async ({
    request,
    response
  }: FetchContext): Promise<void> => {
    console.log(`[fetch response error] - ${request}`);

    const { logOut } = useAuthStore();

    if (response?.status && LOGOUT_ON_ERROR_STATUS.includes(response?.status)) {
      await logOut();
    }
  };

  return {
    onRequest,
    onRequestError,
    onResponse,
    onResponseError
  };
}
