export enum Timeframes {
  Month = 'month',
  Year = 'year',
  AllTime = 'all_time'
}

export interface StatsChartPoint {
  label: string;
  value: number;
}

export interface StatisticParams<T = Timeframes> {
  timeframe?: T;
  unitOffset?: number;
}

export interface StatisticPageMeta {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  dateLabel: string;
}

export interface LevelProgressPoint extends StatsChartPoint {}

export interface WinsPerLevelPoint {
  level: number;
  percentage: number;
}

export interface TrumpsWinsData {
  trumps: StatsChartPoint[];
  nt: StatsChartPoint[];
}
