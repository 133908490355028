import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();

  if (!config.public.sentryDsn) {
    console.warn('Sentry DSN not set, skipping Sentry initialization');
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn,
    environment: config.public.sentryEnv,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],

    tracesSampleRate: config.public.sentryTracesSampleRate
      ? parseFloat(config.public.sentryTracesSampleRate)
      : 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  });
});
