import { useBaseApi } from '@/composables/api';
import type { Tournament } from '@/types/tournaments';
import type { PaginatedData, PaginationParams } from '@/types/pagination';

export const useTournaments = (
  params: PaginationParams & { week?: number }
) => {
  return useBaseApi<PaginatedData<Tournament>>('/tournaments', {
    method: 'get',
    params
  });
};

export const useTournament = (id: number) => {
  return useBaseApi<Tournament>(`/tournaments/${id}`, {
    method: 'get'
  });
};

export const useCurrentTournament = () => {
  return useBaseApi<Tournament>('/tournaments/current', {
    method: 'get'
  });
};
