import { WINS_PET_LEVEL_STORE_KEY } from '@/stores/statistic/constants';
import { Timeframes, type WinsPerLevelPoint } from '@/types/statistic';
import { useWinsPerLevel } from '@/composables/api/actions/statistic';
import { useWatchParam } from '@/stores/statistic/helpers';

export const useWinsPerLevelStore = defineStore(
  WINS_PET_LEVEL_STORE_KEY,
  () => {
    const timeframe = ref<Timeframes>(Timeframes.Month);
    const data = ref<WinsPerLevelPoint[]>([]);

    async function getData() {
      const response = await useWinsPerLevel({ timeframe: timeframe.value });

      if (response.data) {
        data.value = response.data;
      }

      return response;
    }

    function resetStore() {
      data.value = [];
      timeframe.value = Timeframes.Month;
    }

    useWatchParam(timeframe, getData);

    return {
      timeframe,
      data,
      getData,
      resetStore
    };
  }
);
