import { default as coursesHOYOiJMPBAMeta } from "/tmp/build_0f68ffc4/pages/courses.vue?macro=true";
import { default as index4fWp9HdXeLMeta } from "/tmp/build_0f68ffc4/pages/index.vue?macro=true";
import { default as loginocEIaIy6eaMeta } from "/tmp/build_0f68ffc4/pages/login.vue?macro=true";
import { default as playSnfTMNC10LMeta } from "/tmp/build_0f68ffc4/pages/play.vue?macro=true";
import { default as handlejJjZ6YqZA2Meta } from "/tmp/build_0f68ffc4/pages/settings/handle.vue?macro=true";
import { default as indexWXktro4BkeMeta } from "/tmp/build_0f68ffc4/pages/settings/index.vue?macro=true";
import { default as sign_45upUSZKr1YdEIMeta } from "/tmp/build_0f68ffc4/pages/sign-up.vue?macro=true";
import { default as statisticCQS4LxoIoRMeta } from "/tmp/build_0f68ffc4/pages/statistic.vue?macro=true";
import { default as tournamentpbMotnVhZ3Meta } from "/tmp/build_0f68ffc4/pages/tournament.vue?macro=true";
export default [
  {
    name: coursesHOYOiJMPBAMeta?.name ?? "courses",
    path: coursesHOYOiJMPBAMeta?.path ?? "/courses",
    meta: coursesHOYOiJMPBAMeta || {},
    alias: coursesHOYOiJMPBAMeta?.alias || [],
    redirect: coursesHOYOiJMPBAMeta?.redirect,
    component: () => import("/tmp/build_0f68ffc4/pages/courses.vue").then(m => m.default || m)
  },
  {
    name: index4fWp9HdXeLMeta?.name ?? "index",
    path: index4fWp9HdXeLMeta?.path ?? "/",
    meta: index4fWp9HdXeLMeta || {},
    alias: index4fWp9HdXeLMeta?.alias || [],
    redirect: index4fWp9HdXeLMeta?.redirect,
    component: () => import("/tmp/build_0f68ffc4/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginocEIaIy6eaMeta?.name ?? "login",
    path: loginocEIaIy6eaMeta?.path ?? "/login",
    meta: loginocEIaIy6eaMeta || {},
    alias: loginocEIaIy6eaMeta?.alias || [],
    redirect: loginocEIaIy6eaMeta?.redirect,
    component: () => import("/tmp/build_0f68ffc4/pages/login.vue").then(m => m.default || m)
  },
  {
    name: playSnfTMNC10LMeta?.name ?? "play",
    path: playSnfTMNC10LMeta?.path ?? "/play",
    meta: playSnfTMNC10LMeta || {},
    alias: playSnfTMNC10LMeta?.alias || [],
    redirect: playSnfTMNC10LMeta?.redirect,
    component: () => import("/tmp/build_0f68ffc4/pages/play.vue").then(m => m.default || m)
  },
  {
    name: handlejJjZ6YqZA2Meta?.name ?? "settings-handle",
    path: handlejJjZ6YqZA2Meta?.path ?? "/settings/handle",
    meta: handlejJjZ6YqZA2Meta || {},
    alias: handlejJjZ6YqZA2Meta?.alias || [],
    redirect: handlejJjZ6YqZA2Meta?.redirect,
    component: () => import("/tmp/build_0f68ffc4/pages/settings/handle.vue").then(m => m.default || m)
  },
  {
    name: indexWXktro4BkeMeta?.name ?? "settings",
    path: indexWXktro4BkeMeta?.path ?? "/settings",
    meta: indexWXktro4BkeMeta || {},
    alias: indexWXktro4BkeMeta?.alias || [],
    redirect: indexWXktro4BkeMeta?.redirect,
    component: () => import("/tmp/build_0f68ffc4/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45upUSZKr1YdEIMeta?.name ?? "sign-up",
    path: sign_45upUSZKr1YdEIMeta?.path ?? "/sign-up",
    meta: sign_45upUSZKr1YdEIMeta || {},
    alias: sign_45upUSZKr1YdEIMeta?.alias || [],
    redirect: sign_45upUSZKr1YdEIMeta?.redirect,
    component: () => import("/tmp/build_0f68ffc4/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: statisticCQS4LxoIoRMeta?.name ?? "statistic",
    path: statisticCQS4LxoIoRMeta?.path ?? "/statistic",
    meta: statisticCQS4LxoIoRMeta || {},
    alias: statisticCQS4LxoIoRMeta?.alias || [],
    redirect: statisticCQS4LxoIoRMeta?.redirect,
    component: () => import("/tmp/build_0f68ffc4/pages/statistic.vue").then(m => m.default || m)
  },
  {
    name: tournamentpbMotnVhZ3Meta?.name ?? "tournament",
    path: tournamentpbMotnVhZ3Meta?.path ?? "/tournament",
    meta: tournamentpbMotnVhZ3Meta || {},
    alias: tournamentpbMotnVhZ3Meta?.alias || [],
    redirect: tournamentpbMotnVhZ3Meta?.redirect,
    component: () => import("/tmp/build_0f68ffc4/pages/tournament.vue").then(m => m.default || m)
  }
]