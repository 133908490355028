import { LEVEL_PROGRESS_STORE_KEY } from '@/stores/statistic/constants';
import {
  Timeframes,
  type LevelProgressPoint,
  type StatisticPageMeta
} from '@/types/statistic';
import { useLevelProgress } from '@/composables/api/actions/statistic';
import { useWatchParam } from '@/stores/statistic/helpers';

export const useLevelProgressStore = defineStore(
  LEVEL_PROGRESS_STORE_KEY,
  () => {
    const timeframe = ref<Timeframes>(Timeframes.Month);
    const unitOffset = ref(0);
    const data = ref<LevelProgressPoint[]>([]);
    const fourWeeksData = ref<LevelProgressPoint[]>([]);
    const meta = ref<StatisticPageMeta>({
      hasPreviousPage: false,
      hasNextPage: false,
      dateLabel: ''
    });

    const dataIsEmpty = computed<boolean>(() => {
      return !data.value?.length;
    });

    const fourWeeksDataIsEmpty = computed<boolean>(() => {
      return !fourWeeksData.value?.length;
    });

    async function getData() {
      const response = await useLevelProgress({
        timeframe: timeframe.value,
        unitOffset: unitOffset.value
      });

      if (response.data) {
        data.value = response.data.data;
        meta.value = response.data.meta;
      }

      return response;
    }

    async function getFourWeeksData() {
      const response = await useLevelProgress({ timeframe: Timeframes.Month });

      if (response.data) {
        fourWeeksData.value = response.data.data;
      }

      return response;
    }

    function resetStore() {
      data.value = [];
      fourWeeksData.value = [];
      timeframe.value = Timeframes.Month;
      unitOffset.value = 0;
      meta.value = {
        hasPreviousPage: false,
        hasNextPage: false,
        dateLabel: ''
      };
    }

    useWatchParam(timeframe, getData);
    useWatchParam(unitOffset, getData);

    return {
      timeframe,
      unitOffset,
      data,
      dataIsEmpty,
      meta,
      fourWeeksData,
      fourWeeksDataIsEmpty,
      getData,
      getFourWeeksData,
      resetStore
    };
  }
);
