<script setup lang="ts">
import type { NuxtError } from 'nuxt/app';
import { useAuthStore } from '@/stores/auth';

const config = useRuntimeConfig();
const { loggedIn } = storeToRefs(useAuthStore());

const props = defineProps({
  error: Object as () => NuxtError
});

const errorMessage = computed(() => {
  return props.error?.statusMessage || 'Something went wrong';
});

const homeUrl = computed(() => {
  return loggedIn.value
    ? config.public.homePageUrl
    : config.public.landingPageUrl;
});

const homeRedirect = () => clearError({ redirect: homeUrl.value });
</script>

<template>
  <NuxtLayout name="error">
    <div class="grid gap-6">
      <h1
        class="text-center text-6xl md:text-8xl font-medium text-brand-navy-700"
      >
        {{ error.statusCode }}
      </h1>
      <h2 class="text-center text-lg text-brand-navy-700">
        {{ errorMessage }}
      </h2>
      <div class="flex justify-center">
        <Button @click="homeRedirect">Go back home</Button>
      </div>
    </div>
  </NuxtLayout>
</template>
