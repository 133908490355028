import { STORE_KEY } from '@/stores/modal/constants';
import type { Component } from 'vue';

interface ModalPayload {
  modal: Component;
  props?: Record<string, any>;
}

export const useModalStore = defineStore(STORE_KEY, () => {
  const modal = shallowRef<Component | null>(null);
  const props = ref<Record<string, any>>({});

  function openModal(payload: ModalPayload) {
    modal.value = payload.modal;
    if (payload.props) {
      props.value = payload.props;
    }
    if (document) {
      document.body.setAttribute('data-modal', 'true');
    }
  }

  function closeModal() {
    modal.value = null;
    props.value = {};
    if (document) {
      document.body.removeAttribute('data-modal');
    }
  }

  return {
    modal,
    props,
    openModal,
    closeModal
  };
});
