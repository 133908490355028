import type { Ref } from 'vue';
import type { ApiResponse } from '@/composables/api/types';
import { useAuthStore } from '@/stores/auth';
import useToast from '@/composables/helpers/useToast';

export const useWatchParam = <P, T>(
  param: Ref<P>,
  getDataFn: () => Promise<ApiResponse<T> | undefined>
) => {
  const toast = useToast();

  const loading = ref(false);

  watch(param, async (_value) => {
    const authStoreRefs = storeToRefs(useAuthStore());

    if (!loading.value && toValue(authStoreRefs.loggedIn)) {
      loading.value = true;

      const response = await getDataFn();

      if (response?.error) {
        toast.error('Something went wrong');
      }

      loading.value = false;
    }
  });
};
