<script lang="ts" setup>
import { AvatarColor } from '@/types/profile';
import useAvatarThemeColor from '@/composables/helpers/useAvatarThemeColor';

interface Props {
  currentColor?: AvatarColor;
}

defineProps<Props>();

const emit = defineEmits(['selectColor']);

const colorOptions = computed<AvatarColor[]>(() => {
  return Object.values(AvatarColor);
});
</script>

<template>
  <div class="flex flex-col">
    <h3 class="text-brand-navy-600 text-lg leading-6 font-medium mb-3">
      1. Choose your colour
    </h3>
    <ul
      class="grid grid-cols-5 gap-2 w-max md:w-auto items-start !list-none !ml-0"
    >
      <li
        v-for="color in colorOptions"
        :key="color"
        :class="[
          'w-9 h-9 rounded-full cursor-pointer border',
          color === currentColor ? 'border-brand-navy-600' : 'border-white'
        ]"
        :style="{ backgroundColor: useAvatarThemeColor(color) }"
        @click="emit('selectColor', color)"
      />
    </ul>
  </div>
</template>
