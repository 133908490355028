import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import { jwtDecode } from 'jwt-decode';
import type { ApiResponse, TokenPayload } from '@/composables/api/types';
import type { AsyncData, NuxtError } from 'nuxt/app';

export const formatRequestData = (requestData: any) => {
  return snakecaseKeys(requestData, { deep: true });
};

export const formatResponseData = <T>(responseData: any): T => {
  return camelcaseKeys(responseData, { deep: true });
};

export const addHeaders = (
  payload: Record<string, string>,
  currentHeaders?: HeadersInit
) => {
  const headers = new Headers(currentHeaders);
  for (const headerName in payload) {
    headers.set(headerName, payload[headerName]);
  }

  return headers;
};

export const getTokenExpiration = <T extends TokenPayload>(
  token: string
): Date => {
  const decoded = jwtDecode<T>(token);
  return new Date(decoded.exp * 1000);
};

export const isTokenExpired = <T extends TokenPayload>(
  token: string
): boolean => {
  return Date.now() >= getTokenExpiration<T>(token).getTime();
};

export const normalizeAsyncDataResponse = async <T>(
  request: AsyncData<ApiResponse<T>, NuxtError | null>
): Promise<ApiResponse<T>> => {
  const response = await request;
  const error = toValue(response.error);

  if (error) {
    return {
      data: undefined,
      error
    };
  }

  return toValue(response.data);
};
