import defaultTheme from 'tailwindcss/defaultTheme';
import typography from '@tailwindcss/typography';
import forms from '@tailwindcss/forms';

/** @type {import('tailwindcss').Config} */
export default {
  content: [
    './components/**/*.{js,vue,ts}',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './plugins/**/*.{js,ts}',
    './app.vue',
    './error.vue'
  ],
  theme: {
    extend: {
      colors: {
        'brand-red': {
          200: '#D64C10',
          300: '#F02E2F',
          400: '#D51F1F',
          500: '#C91919'
        },
        'brand-navy': {
          50: '#F0F5FF',
          100: '#BAC1CA',
          150: '#818DA2',
          200: '#3F4A58',
          300: '#354561',
          400: '#214377',
          500: '#172E55',
          600: '#122546',
          700: '#07193D'
        },
        'brand-white': '#FAFAF9',
        'brand-light-gray': '#F0F1F2',
        'brand-gray': '#D1D4Da',
        'brand-medium-gray': '#939393',
        'brand-dark-gray': '#595959',
        'brand-light-orange': '#FDE9D7',
        'brand-light-blue': '#D7EDFD',
        'brand-lightest-blue': '#E4EEF7',
        'brand-light-green': '#DAF5D1',
        'brand-light-purple': '#F5DFFA',
        'brand-light-red': '#FDE0DE',
        'suit-green': '#32A03D',
        'suit-yellow': '#FFBA0A',
        'suit-red': '#C91919',
        'suit-blue': '#1E37BC'
      },
      fontFamily: {
        sans: ['DM Sans', ...defaultTheme.fontFamily.sans]
      },
      gridTemplateColumns: {
        fluid: 'repeat(auto-fit, minmax(1rem, max-content))'
      }
    }
  },
  plugins: [typography, forms({ strategy: 'class' })]
};
